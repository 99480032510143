import styled from 'styled-components'


export const StyleInput = styled.div`
            display:flex;
            flex-direction:column;
            label{
                font-size:13px;
                color:#696464;
            }        
    `