import styled from 'styled-components'


export const ContentStyled = styled.div`

`

export const Title = styled.h1`
    margin:15px 0;


`

