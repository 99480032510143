import React from "react";

function IconTrain() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path d="M453.968 339.946V226.37h-42.221v-72.049h.821l43.011-43.012-15.77-63.085H307.676l-15.772 63.085 43.011 43.012h.821v72.049h-131.8V110.837h28.484v-30H0v318.074h24.231c1.722-48.889 42.023-88.132 91.325-88.132 31.792 0 59.839 16.321 76.217 41.021 16.377-24.7 44.425-41.021 76.217-41.021 49.301 0 89.603 39.243 91.325 88.132h24.846v64.865H512v-56.127zM173.936 176.063v50.307h-78.71v-50.307z"></path>
      <path d="M267.989 340.778c-28.729 0-52.842 19.737-59.536 46.39h-33.362c-6.694-26.652-30.807-46.39-59.536-46.39-33.904 0-61.389 27.485-61.389 61.389s27.485 61.389 61.389 61.389c28.729 0 52.841-19.737 59.535-46.389h33.362c6.694 26.652 30.807 46.389 59.536 46.389 33.904 0 61.389-27.485 61.389-61.389s-27.483-61.389-61.388-61.389z"></path>
    </svg>
  );
}

export default IconTrain;
