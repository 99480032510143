import React, { FC } from "react"

const Loader:FC<{}> = ({}) => {


    return (
      <div className="ui active loader big"></div>
        
    )
}


export default Loader