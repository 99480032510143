import React from "react";

function IconCar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.055"
      height="39.054"
      x="0"
      y="0"
      enableBackground="new 0 0 39.055 39.054"
      version="1.1"
      viewBox="0 0 39.055 39.054"
      xmlSpace="preserve"
    >
      <path d="M38.831 14.26a1.005 1.005 0 00-.775-.369h-3.801c-.938-2.474-2.16-4.898-3.549-5.813-4.805-3.161-17.55-3.161-22.355 0-1.39.916-2.607 3.343-3.55 5.813H1a.997.997 0 00-.775.369.995.995 0 00-.204.834l.563 2.728a1 1 0 00.979.797h1.126c-1.087 1.254-1.614 2.833-1.621 4.413-.007 1.952.734 3.716 2.089 4.964.015.013.03.022.044.035v3.817c0 .827.672 1.5 1.5 1.5h3.506c.828 0 1.5-.673 1.5-1.5v-1.534h19.641v1.534c0 .827.672 1.5 1.5 1.5h3.506c.826 0 1.5-.673 1.5-1.5v-3.742c1.438-1.317 2.125-3.129 2.134-4.938.006-1.634-.545-3.271-1.696-4.551h1.201a.996.996 0 00.979-.798l.564-2.727a1.008 1.008 0 00-.205-.832zM9.998 10.583c3.83-2.521 15.229-2.521 19.057 0 .744.488 1.701 2.461 2.578 4.877H7.422c.875-2.415 1.832-4.387 2.576-4.877zM5.512 23.408a2.95 2.95 0 115.901.001 2.95 2.95 0 01-5.901-.001zm25.119 2.951a2.951 2.951 0 110-5.903 2.951 2.951 0 010 5.903z"></path>
    </svg>
  );
}

export default IconCar;
