import React, { FC } from "react"



const NotFound:FC<{}> = ({}) => {


    return (<h1>sorry not found </h1>)
}


export default NotFound