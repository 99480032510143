import styled from 'styled-components'

export const StyleContainer =styled.div`
margin:5% 20%;
    body:focus-within {
        background: red;
    }
`

export const ErrorComponent = styled.div`
    color:red;
    font-size:30px;
    text-align:center

`